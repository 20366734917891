/*eslint-disable */
// importeventTypeModel from '../../Model/event_type'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"

let eventTypes = {
  /**
   *eventTypeList
   */
  async eventTypeList (context) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "eventType_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at eventTypeList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   *eventTypeView
   */
  async eventTypeView (context, eventTypeId) {
    try {
      
      let post_data = new FormData();
      post_data.append('event_type_id',eventTypeId);
      return await request.curl(context, "eventType_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at eventTypeList() and Exception:",err.message)
    }

  },

  /**
   *eventTypeAdd
   */
  async eventTypeAdd (context, eventTypeObj) {
    try{
    let post_data = new FormData();
    

    for (let key in eventTypeObj) {
      post_data.append(key,eventTypeObj[key]);
    }

    return await request.curl(context, "eventType_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at eventTypeAdd() and Exception:',err.message)
    }
  },

  /**
   *eventTypeEdit
   */
  async eventTypeEdit (context, eventTypeObj) {
    try{
    let post_data = new FormData();
    
    for (let key in eventTypeObj) {
      post_data.append(key, eventTypeObj[key]);
    }

    return await request.curl(context, "eventType_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at eventTypeEdit() and Exception:',err.message)
    }
  },

  /**
   *eventTypeDelete
   */
  async eventTypeDelete (context, eventTypeId) {
    try{
    let post_data = new FormData();

    post_data.append('event_type_id',eventTypeId);

    return await request.curl(context, "eventType_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at eventTypeDelete() and Exception:',err.message)
    }
  }
}

export {
 eventTypes
}
