<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template>
            <p v-show="!propOpenedInModal">
              {{cvCardSubHeader}}
            </p>
            <form action="#" v-if="vmeventTypeFormData && Object.keys(vmeventTypeFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationeventType_name">
                    {{cvProfBulActTypeNameLabel}}</label>
                  <input
                    v-model="vmeventTypeFormData.event_type_name"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              </div>
            <div class="form-group row spinner_add">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button v-else type="button" class="btn btn-primary" @click="eventTypeEdit()">
                  {{cvSubmitBtn}}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { eventTypes } from "../../../FackApi/api/eventType"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "eventTypeEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propeventTypeObj: {
      type: Object,
      default: function () {
        return {
          "event_type_name": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit Event Type",
      cvCardSubHeader: "Edit Event Type",
      cvSubmitBtn: "Edit",
      cvProfBulActTypeNameLabel: "Activity Type Name",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event Type",
      vmeventTypeFormData: {},
      cvLoadingStatus: false
    }
  },
  mounted () {
    this.eventTypeView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmeventTypeFormData) {
          if (!this.vmeventTypeFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * eventTypeView
     */
    async eventTypeView () {
      try {
        if (this.propOpenedInModal) {
          this.vmeventTypeFormData = this.propeventTypeObj
        }
        else {
          let eventTypeId = this.$route.params.event_type_id
          let eventTypeViewResp = await eventTypes.eventTypeView(this, eventTypeId)
          if (eventTypeViewResp && eventTypeViewResp.resp_status) {
            this.vmeventTypeFormData = eventTypeViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at eventTypeView() and Exception:", err.message)
      }
    },
    /**
     * eventTypeEdit
     */
    async eventTypeEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let eventTypeAddResp = await eventTypes.eventTypeEdit(this, this.vmeventTypeFormData)
        await ApiResponse.responseMessageDisplay(this, eventTypeAddResp)
        if (eventTypeAddResp && !eventTypeAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitcloseeventTypeEditModal", this.vmeventTypeFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at eventTypeEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
