<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="eventTypeAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template>
            <b-row>
              <b-col sm="6" md="6" lg="6" xl="6" class="mb-2">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="eventTypeObjList && eventTypeObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="eventTypeObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="eventTypeEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant=" iq-bg-danger" @click="showeventTypeDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModeleventTypeAdd"
      scrollable
      :title="cvAddModalHeader">
      <eventTypeAdd :propOpenedInModal="true" @emitcloseeventTypeAddModal="closeeventTypeAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="eventTypeAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeeventTypeAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModeleventTypeEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <eventTypeEdit :propOpenedInModal="true" :propeventTypeObj="eventTypeEditObj" @emitcloseeventTypeEditModal="closeeventTypeEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="eventTypeEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeeventTypeEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModeleventTypeDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModeleventTypeDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="eventTypeDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style>
.iq-card .iq-card-header{
  padding: 0px;
}
</style>
<script>
import { eventTypes } from "../../../FackApi/api/eventType"
import eventTypeAdd from "./EventTypeAdd.vue"
import eventTypeEdit from "./EventTypeEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "eventTypeList",
  components: {
    eventTypeAdd,
    eventTypeEdit
  },
  data () {
    return {
      cvCardTitle: "Event Types",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Event Type",
      cvAddModalHeader: "Add Event Type",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "eventType List Response",
      showModeleventTypeAdd: false,
      showModeleventTypeEdit: false,
      showModeleventTypeDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },

        { label: "Event type name", key: "event_type_name", class: "text-left", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left", sortable: true },
        { label: "Action ", key: "action", class: "text-left", sortable: false }
      ],
      eventTypeObjList: null,
      eventTypeEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null
    }
  },
  mounted () {
    this.eventTypeList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * eventTypeList
     */
    async eventTypeList () {
      try {
        let eventTypeListResp = await eventTypes.eventTypeList(this)
        if (eventTypeListResp.resp_status) {
          this.eventTypeObjList = eventTypeListResp.resp_data.data
          this.totalRows = eventTypeListResp.resp_data.count
        }
        else {
          this.toastMsg = eventTypeListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at eventTypeList() and Exception:", err.message)
      }
    },
    /**
     * eventTypeAdd
     */
    eventTypeAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/eventType_add")
        }
        else {
          this.showModeleventTypeAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at eventTypeAdd() and Exception:", err.message)
      }
    },
    /**
     * eventTypeEdit
     */
    eventTypeEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/eventType_edit/" + this.eventTypeEditObj.event_type_id)
        }
        else {
          this.eventTypeEditObj = item
          this.showModeleventTypeEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at eventTypeEdit() and Exception:", err.message)
      }
    },
    /**
     * showeventTypeDeleteDialog
     */
    showeventTypeDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModeleventTypeDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showeventTypeDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * eventTypeDelete
     */
    async eventTypeDelete () {
      try {
        let eventTypeDelResp = await eventTypes.eventTypeDelete(this, this.delObj.event_type_id)
        await ApiResponse.responseMessageDisplay(this, eventTypeDelResp)
        if (eventTypeDelResp && !eventTypeDelResp) {
          this.showModeleventTypeDelete = false
          return false
        }
        let index = this.eventTypeObjList.indexOf(this.delObj)
        this.eventTypeObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModeleventTypeDelete = false
      }
      catch (err) {
        console.error("Exception occurred at eventTypeDelete() and Exception:", err.message)
      }
    },
    /**
     * closeeventTypeAddModal
     */
    closeeventTypeAddModal (eventTypeAddData) {
      try {
        if (eventTypeAddData) {
          if (this.eventTypeObjList && this.eventTypeObjList.length >= 1) {
            let profacttypeObjLength = this.eventTypeObjList.length
            let lastId = this.eventTypeObjList[profacttypeObjLength - 1]["id"]
            eventTypeAddData.id = lastId + 1
          }
          else {
            this.eventTypeObjList = []
            eventTypeAddData.id = 11111
          }
          // eventTypeAddData.event_type_id = `EVETYPE${eventTypeAddData.id}`
          eventTypeAddData.created_on = moment()
          this.eventTypeObjList.unshift(eventTypeAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModeleventTypeAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeeventTypeAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeeventTypeEditModal
     */
    closeeventTypeEditModal () {
      try {
        this.showModeleventTypeEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeeventTypeEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
