<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationeventType_name">
                    {{cvProfBulActTypeNameLabel}}</label>
                  <input
                    v-model="vmeventTypeFormData.event_type_name"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus"
                        type="button"
                        class="btn btn-primary"
                        :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button v-else
                        type="button"
                        class="btn btn-primary"
                        @click="eventTypeAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { eventTypes } from "../../../FackApi/api/eventType"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "eventTypeAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add eventType",
      cvCardSubHeader: "Add Event Type ",
      cvSubmitBtn: "Add",
      cvProfBulActTypeNameLabel: "Activity Type Name",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event Type",
      vmeventTypeFormData: Object.assign({}, this.initFormData()),
      vmeventTypeDesc: null,
      vmeventTypeType: null,
      cvLoadingStatus: false
    }
  },
  mounted () {
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "event_type_name": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmeventTypeFormData) {
          if (!this.vmeventTypeFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * eventTypeAdd
     */
    async eventTypeAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let eventTypeAddResp = await eventTypes.eventTypeAdd(this, this.vmeventTypeFormData)
        await ApiResponse.responseMessageDisplay(this, eventTypeAddResp)
        if (eventTypeAddResp && !eventTypeAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitcloseeventTypeAddModal", eventTypeAddResp.resp_data)
        }
        this.vmeventTypeFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at eventTypeAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
